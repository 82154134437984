import PeoplesService from "@/modules/peoples/peoples-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            PeoplesService.create(payload).then(response => {
                resolve({
                    message: "Peoples criada com sucesso",
                    success: true,
                    data: {
                        ...response.data,
                        photo_view: response.data.photo
                    }
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    update(id, payload) {
        return new Promise((resolve, reject) => {
            PeoplesService.update(id, payload).then(response => {
                resolve({
                    message: "Peoples atualizada com sucesso",
                    success: true,
                    data: {
                        ...response.data,
                        photo_view: response.data.photo
                    }
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            PeoplesService.remove(id).then(response => {
                resolve({
                    message: "Peoples removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            PeoplesService.delete(id).then(response => {
                resolve({
                    message: "Peoples deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    deletePeopleSchool(id) {
        return new Promise((resolve, reject) => {
            PeoplesService.deletePeopleSchool(id).then(response => {
                resolve({
                    message: "Peoples deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            PeoplesService.get(id).then(response => {
                resolve({
                    message: "Peoples encontrada com sucesso",
                    success: true,
                    data: {
                        ...response.data,
                        photo_view: response.data.photo
                    }
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            PeoplesService.paginate(query).then(response => {
                resolve({
                    message: "Peoples encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Peoples",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            PeoplesService.all().then(response => {
                resolve({
                    message: "Peoples encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Peoples",
                    success: false,
                    error
                });
            })
        })
    }
}
