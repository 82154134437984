<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import SchoolsContractsStore from "@/modules/schools-contracts/schools-contracts-store";
import {is_admin, is_genesis_admin} from "@/router/acl";
import SchoolsStore from "@/modules/schools/schools-store";
import ContractsStore from "@/modules/contracts/contracts-store";
import PeoplesStore from "@/modules/peoples/peoples-store";
import Swal from "sweetalert2";
import ContractsCommentsStore from "@/modules/contracts-comments/contracts-comments-store";
import ContractsAttachmentsStore from "@/modules/contracts-attachments/contracts-attachments-store";
import ContractsSubscriptionsStore from "@/modules/contracts-subscriptions/contracts-subscriptions-store";

export default {
  data: () => {
    return {
      title: "Meu contrato",
      items: [
        {
          text: "Genesis",
          href: "/",
        },
        {
          text: "Contrato",
          active: true,
        },
      ],
      comentarios: [
        {
          id: 1,
          comentario: 'Peço que seja feito a entrega no dia 01/01/2021',
          data: '01/01/2021',
          usuario: 'João da Silva Junior',
        },
        {
          id: 2,
          comentario: 'Ajuste o valor da parcela para R$ 500,00',
          data: '01/01/2021',
          usuario: 'Pedro J.',
        },
        {
          id: 3,
          comentario: 'Ajuste o texto do contrato no item 1.1',
          data: '01/01/2021',
          usuario: 'Mario Alencar',
        },
      ],
      isLoadPage: false,
      contrato: false,
      optionsSchools: [{value: null, text: "Selecione a escola"}],
      optionsContracts: [{value: null, text: "Selecione o contrato"}],
      optionsPeoples: [{value: null, text: "Selecione a pessoa"}],
      optionsStatus: [
        {value: "pending", text: "Pendente"},
        {value: "resume", text: "Resumo"},
        {value: "publish", text: "Publicado"},
      ],
      optionsLocalKey: [
        {value: null, text: "Selecione o local"},
        {value: "generic", text: "Genérico"},
        {value: "contract", text: "Contrato"},
      ],
      campoComentario: null,
      isCampoComentario: false,
      loadingFormDocument: false,
      loadingFormFile: false,
      uploadDocument: null,
      fileUpload: null,
      local_key: null,
      descricao: null,
      perfil: null,
    }
  },
  components: {Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));
    this.findContrato();
  },
  methods: {
    handlerUploadFile(event) {
      this.fileUpload = event.target.files[0];
    },
    handlerUploadDocument(event) {
      this.uploadDocument = event.target.files[0];
    },
    is_admin,
    is_genesis_admin,
    async loadOptionsSchools() {
      await SchoolsStore.all().then((response) => {
        this.optionsSchools.push(...response.data.map((item) => {
          return {value: item.id, text: item.name};
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsContracts() {
      await ContractsStore.all().then((response) => {
        console.log('response  xx1', response);
        this.optionsContracts.push(...response.data.data.map((item) => {
          return {value: item.id, text: item.name};
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsPeoples() {
      await PeoplesStore.all().then((response) => {
        this.optionsPeoples.push(...response.data.data.map((item) => {
          return {value: item.id, text: item.name};
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async findContrato() {
      const id = this.$route.params.id;

      this.isLoadPage = true;

      if (is_admin() || is_genesis_admin()) {
        await this.loadOptionsSchools();
        await this.loadOptionsContracts();
        await this.loadOptionsPeoples();
      }

      SchoolsContractsStore.get(id).then((response) => {
        this.contrato = response.data;
        this.contrato.date_start = this.contrato.date_start.split('T')[0];
        this.contrato.date_stop = this.contrato.date_stop.split('T')[0];
        console.log(this.contrato);
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.isLoadPage = false;
      });

    },
    save(e) {
      e.preventDefault();

      this.isLoadPage = true;

      delete this.contrato.contracts;
      delete this.contrato.peoples;
      delete this.contrato.schools;
      delete this.contrato.schools_contracts;

      if (this.contrato.id) {
        SchoolsContractsStore.update(this.contrato).then((response) => {
          this.contrato = response.data;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Contrato de escola atualizado com sucesso",
          });
          this.findContrato();
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o Contrato de escola",
          });
          this.isLoadPage = false;
        }).finally(() => {
          this.isLoadPage = false;
        });
      }
    },
    getUrlFile(url) {
      let urlFinal = url.split('/');
      let bucket = urlFinal[5];
      let final = urlFinal[urlFinal.length - 1];
      final = decodeURIComponent(final.split('?')[0]);
      const origem = 'https://storage.googleapis.com/'
      return `${origem}${bucket}/${final}`;
    },

    saveCommentario(e) {
      e.preventDefault();
      console.log(this.campoComentario);

      const form = {
        id_schools_contracts: this.contrato.id,
        comment: this.campoComentario,
        id_peoples: this.perfil.id_people
      }

      this.isCampoComentario = true;

      ContractsCommentsStore.create(form).then((response) => {
        this.campoComentario = null;
        this.findContrato();
        Swal.fire({
          icon: "success",
          title: "Criado",
          text: "Comentário do contrato criado com sucesso",
        });
        this.isCampoComentario = false;
      }).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao criar",
          text: "Erro ao criar o Comentário do contrato",
        });
        this.isCampoComentario = false;
      });


    },
    saveDocumento(e, idPeople) {
      e.preventDefault();
      this.loadingFormDocument = true;

      const formData = new FormData();
      formData.append("id_schools_contracts", this.contrato.id);
      formData.append("id_peoples", idPeople);
      formData.append("local", this.uploadDocument);



      ContractsSubscriptionsStore.create(formData).then((response) => {
        this.uploadDocument = null;
        this.descricao = null;
        this.findContrato();
        Swal.fire({
          icon: "success",
          title: "Criado",
          text: "Assinatura do contrato enviada com sucesso",
        });
        this.loadingFormDocument = false;
      }).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao criar",
          text: "Erro ao enviar a Assinatura do contrato",
        });
        this.loadingFormDocument = false;
      });
    },
    saveFile(e) {
      e.preventDefault();
      this.loadingFormFile = true;

      const formData = new FormData();
      formData.append("id_schools_contracts", this.contrato.id);
      formData.append("local_key", this.local_key);
      formData.append("local", this.fileUpload);
      formData.append("description", this.descricao);

      ContractsAttachmentsStore.create(formData).then((response) => {
        this.fileUpload = null;
        this.local_key = null;
        this.findContrato();
        Swal.fire({
          icon: "success",
          title: "Criado",
          text: "Arquivo do contrato criado com sucesso",
        });
        this.loadingFormFile = false;
      }).catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Erro ao criar",
          text: "Erro ao criar o Arquivo do contrato",
        });
        this.loadingFormFile = false;
      });
    },
    findSubscription(idPeople) {
      return this.contrato.contracts_subscriptions.find((item) => {
        return item.id_peoples == idPeople;
      });
    },
    getContrato() {
      if (this.contrato.contracts_attachments) {
        for (let i = 0; i < this.contrato.contracts_attachments.length; i++) {
          if (this.contrato.contracts_attachments[i].local_key == 'contract') {
            return this.contrato.contracts_attachments[i].local;
          }
        }
      }
      return this.contrato.contracts.file;
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="isLoadPage">
      <div class="row">
        <div class="col-lg-12" v-if="contrato">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>
            </div>
          </div>

          <br>
          <br>


          <div class="card" v-if="is_admin() || is_genesis_admin()">
            <div class="card-body">
              <b-form @submit="save" class="was-validated row">

                <b-form-group class="col-6 col-md-3" label="Escola" label-for="id_schools" description="Seleciona a escola">
                  <b-form-select
                      id="id_schools"
                      v-model="contrato.id_schools"
                      :options="optionsSchools"
                      required
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Contrato" label-for="id_contracts" description="Seleciona o contrato">
                  <b-form-select
                      id="id_contracts"
                      v-model="contrato.id_contracts"
                      :options="optionsContracts"
                      required
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Pessoa" label-for="id_peoples" description="Seleciona a pessoa">
                  <b-form-select
                      id="id_peoples"
                      v-model="contrato.id_peoples"
                      :options="optionsPeoples"
                      required
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Status" label-for="status" description="Seleciona o status">
                  <b-form-select
                      v-model="contrato.status"
                      :options="optionsStatus"
                      required
                  ></b-form-select>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Descrição" label-for="description" description="Descrição do contrato">
                  <b-form-textarea
                      id="description"
                      v-model="contrato.description"
                      required
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Versão" label-for="version" description="Versão do contrato">
                  <b-form-input
                      id="version"
                      v-model="contrato.version"
                      required
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Data de início" label-for="date_start" description="Data de início do contrato">
                  <b-form-input
                      type="date"
                      v-model="contrato.date_start"
                  ></b-form-input>
                </b-form-group>

                <b-form-group class="col-6 col-md-3" label="Data de término" label-for="date_stop" description="Data de término do contrato">
                  <b-form-input
                      type="date"
                      v-model="contrato.date_stop"
                  ></b-form-input>
                </b-form-group>


                <div class="col-4">
                  <b-button type="submit" variant="primary">
                    <span v-if="contrato.id">Salvar alterações</span>
                    <span v-if="!contrato.id">Cadastrar novo</span>
                  </b-button>
                </div>
              </b-form>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <span class="btn btn-success" v-if="contrato.status=='publish'">Aceito</span>
                  <span class="btn btn-warning" v-if="contrato.status=='resume'">rascunho</span>
                  <span class="btn btn-dark" v-if="contrato.status=='pending'">Pendente</span>
                  <h2>#{{ contrato.id }} - {{ contrato.schools.name }}</h2>
                  <h5 v-if="contrato.peoples">Por: {{ contrato.peoples.name }}</h5>
                  <h6 class="color-red">Data de vigencia: {{ contrato.date_start | f_date }} - {{ contrato.date_stop | f_date }}</h6>

                </div>

                <div class="col">
                  <h3>Assinaturas</h3>
                  <div class="row">
                    <div v-for="pessoa in contrato.schools.schools_peoples" :key="pessoa.id" class="col">
                      <h5>{{ pessoa.peoples.name }}</h5>
                      <span class="badge badge-warning" v-if="!findSubscription(pessoa.peoples.id)">pendente</span>
                      <span class="badge badge-info" v-if="findSubscription(pessoa.peoples.id)">assinado</span>
                      <p>CPF: {{ pessoa.peoples.document }}<br>

                        <span v-if="findSubscription(pessoa.peoples.id)">
                          <strong>Data assintura: </strong> {{ findSubscription(pessoa.peoples.id).created_at | f_date_time }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-lg-8">
              <div class="card">
                <div class="card-body">

                  <a class="btn btn-info" :href="getContrato()" target="_blank" download>Download contrato</a>
                  <iframe :src="getContrato()" style="width: 100%; height: 100vh" type="pdf"></iframe>
                  <a class="btn btn-info" :href="getContrato()" target="_blank" download>Download contrato</a>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="card">
                <div class="card-body">

                  <b-tabs content-class="mt-3">
                    <b-tab title="Comentários" active>
                      <h3>Comentários</h3>
                      <br>

                      <b-form @submit="saveCommentario">
                        <h4>Novo comentario</h4>
                        <b-overlay :show="isCampoComentario">
                          <textarea v-model="campoComentario" style="height: 150px;" placeholder="Solicite algum ajuste ao contrato." cols="30" rows="10" class="form-control"></textarea>
                          <br>
                          <button class="btn btn-primary">Enviar</button>
                        </b-overlay>
                      </b-form>
                      <br>


                      <div v-for="item in contrato.contracts_comments" :key="item.id">
                        <h4>{{ item.peoples.name }}</h4>
                        <p>{{ item.comment }}</p>
                        <p><i>{{ item.created_at | f_date_time }}</i></p>
                        <hr>
                      </div>


                    </b-tab>
                    <b-tab title="Arquivos">

                      <h3>Arquivos</h3>
                      <br>

                      <b-overlay :show="loadingFormFile">
                        <b-form @submit="saveFile" class="was-validated">


                          <b-form-group label="Local" label-for="id_schools_contracts" description="Selecione um local">
                            <b-form-select
                                v-model="local_key"
                                :options="optionsLocalKey"
                                placeholder="Selecione um contrato"
                                required
                            ></b-form-select>
                          </b-form-group>

                          <b-form-group label="Arquivo" label-for="local" description="Selecione um arquivo">
                            <b-form-file
                                v-model="fileUpload"
                                @change="handlerUploadFile"
                                required
                                placeholder="Selecione um arquivo"
                            ></b-form-file>
                          </b-form-group>

                          <b-form-group label="Descrição" label-for="description" description="Descrição do arquivo">
                            <b-form-textarea
                                id="description"
                                v-model="descricao"
                                required
                            ></b-form-textarea>
                          </b-form-group>

                          <b-button type="submit" variant="primary">
                            Enviar arquivo
                          </b-button>
                        </b-form>
                      </b-overlay>


                      <br><br>
                      <h4>Arquivos: </h4>

                      <div v-for="item in contrato.contracts_attachments" :key="item.id">
                        <a :href="item.local" target="_blank" class="btn btn-success float-right">Ver arquivo </a>
                        <span>
                           <strong v-if="item.local_key == 'generic'">Geral</strong>
                           <strong v-else>Contrato</strong>
                           {{ item.created_at | f_date_time }}
                          <br><strong>Descrição: </strong>{{ item.description }}
                         </span>


                        <hr>
                      </div>


                    </b-tab>
                    <b-tab title="Assinantes">

                      <h3>Assinaturas de contratos</h3>
                      <br>
                      <br>

                      <div v-for="item in contrato.schools.schools_peoples" :key="item.id">
                        <h4>{{ item.peoples.name }}</h4>
                        <p>
                          {{ item.peoples.document }}<br>
                          {{ item.peoples.date_of_birth | f_date }}
                        </p>


                        <div v-if="findSubscription(item.peoples.id)">
                          <div class="assinatura">
                            <img :src="findSubscription(item.peoples.id).local"/>
                          </div>
                          <strong>Data assintura: </strong> {{ findSubscription(item.peoples.id).created_at | f_date_time }}
                        </div>


                        <b-overlay :show="loadingFormDocument">
                          <b-form @submit="saveDocumento($event,item.peoples.id)" class="was-validated">


                            <b-form-group label="Assinatura" label-for="local" description="Selecione um arquivo">
                              <b-form-file
                                  v-model="uploadDocument"
                                  @change="handlerUploadDocument"
                                  placeholder="Selecione um arquivo"
                              ></b-form-file>

                            </b-form-group>

                            <b-button type="submit" variant="primary">
                              Enviar assinatura
                            </b-button>
                          </b-form>
                        </b-overlay>

                        <br>
                        <hr>
                        <br>

                      </div>

                    </b-tab>
                  </b-tabs>


                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">
.assinatura {
  width: 100%;
  height: 200px;
  background: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  overflow: hidden;
}

.assinatura img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 750px) {
  iframe {
    width: 90% !important;
  }
}
</style>
