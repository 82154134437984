<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import SchoolInput from "@/components/SchoolInput.vue";
import SchoolsStore from "@/modules/schools/schools-store";
import SchoolsInfosStore from "@/modules/schools-infos/schools-infos-store";
import Swal from "sweetalert2";
import inputsSchools from "@/modules/schools/inputsSchools";
import PeoplesTypesStore from "@/modules/peoples-types/peoples-types-store";
import PeoplesRacesStore from "@/modules/peoples-races/peoples-races-store";
import PeoplesStore from "@/modules/peoples/peoples-store";

export default {
  data: () => {
    return {
      title: "Cadastro da escola",
      items: [
        {
          text: "Escola",
          href: "/",
        },
        {
          text: "Dados",
          active: true,
        },
      ],
      form: {
        id: null,
        name: '',
      },
      fields: inputsSchools,
      perfil: null,
      optionsPeopleTypes: [{text: "Selecione", value: null}],
      optionsPeopleRaces: [{text: "Selecione", value: null}],
      optionsSex: [
        {text: "Masculino", value: "M"},
        {text: "Feminino", value: "F"},
        {text: "Juridica", value: "PJ"},
      ],
      isLoadShow: false,
      formPeople: {
        id: null,
        id_people_type: null,
        id_people_race: null,
        sex: null,
        name: null,
        document: null,
        photo: null,
        date_of_birth: null,
      },
    }
  },
  mounted() {


    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));

    this.loadDados();

  },
  methods: {
    async loadDados() {
      this.isLoadShow = true;
      await this.loadOptionsPeoplesTypes();
      await this.loadOptionsPeoplesRaces();
      this.isLoadShow = false;

      const id = this.$route.params.id;
      console.log('id', id);
      if (id) {
        this.isLoadShow = true;
        SchoolsStore.get(id).then((response) => {
          this.form = response.data;
          this.form.schools_peoples.forEach((item) => {
            item.peoples.date_of_birth = item.peoples.date_of_birth.split('T')[0];
          });
        }).finally(() => {
          this.isLoadShow = false;
        });
        SchoolsInfosStore.all({id_schools: id}).then((response) => {
          response.data.data.forEach((item) => {
            console.log('item', item);
            if (this.fields[item.key]) {
              this.fields[item.key].form = item;
            }
          });
        });
      }

    },
    handlerUpload(event) {
      this.form.photo = event.target.files[0];
      console.log(this.form.photo);
    },
    async loadOptionsPeoplesTypes() {
      await PeoplesTypesStore.all().then((response) => {
        this.optionsPeopleTypes.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async loadOptionsPeoplesRaces() {
      await PeoplesRacesStore.all().then((response) => {
        this.optionsPeopleRaces.push(...response.data.map((item) => {
          return {text: item.label, value: item.id}
        }));
      }).catch((error) => {
        console.log(error);
      });
    },
    async updateConfig(value, file = false) {

      const item = this.fields[value.key];
      value.form.id_schools = this.form.id;
      value.form.key = value.key;

      if (file) {
        value.form.value = file;
      } else {
        value.form.value = value.form.value.toString();
      }

      console.log('updateConfig', value, item, file);

      item.isLoad = true;
      item.request = true;
      let response = null;

      if (value.form.id) {
        response = await SchoolsInfosStore.update({
          id: value.form.id,
          key: value.form.key,
          value: value.form.value,
        });
      } else {
        response = await SchoolsInfosStore.create(value.form);
      }
      item.isLoad = false;
      item.request = null;
      if (response.success) {
        this.fields[value.key].form = response.data;
      } else {
        Swal.fire({
          title: 'Erro',
          text: `Erro ao salvar o campo ${item.label}`,
          icon: 'error',
          confirmButtonText: 'OK',
          toast: true,
          timer: 2500,
          timerProgressBar: true,
          position: "top-end",
          showConfirmButton: false,
        });
      }

    },

    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        SchoolsStore.update(this.form).then((response) => {
          this.form = response.data;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Escola atualizada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        this.form.id_people = this.perfil.id_people;
        SchoolsStore.create(this.form).then((response) => {
          this.form = response.data;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Escola criada com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar a escola",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    },
    savePeople(e, people) {
      e.preventDefault();
      console.log('people', people);

      const formData = new FormData();
      if (people.id) {
        formData.append("id", people.id);
      }

      formData.append("id_school", this.form.id);
      formData.append("id_people_type", people.id_people_type);
      formData.append("id_people_race", people.id_people_race);
      formData.append("sex", people.sex);
      formData.append("name", people.name);
      formData.append("document", people.document);
      formData.append("date_of_birth", people.date_of_birth);

      if (people.photo) {
        formData.append("photo", people.photo);
      }


      if (people.id) {
        PeoplesStore.update(people.id, formData).then((response) => {
          people = response.data;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Cadastro atualizado com sucesso",
          });
          this.loadDados();
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o Cadastro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        PeoplesStore.create(formData).then((response) => {
          people = response.data;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Cadastro criado com sucesso",
          });
          this.formPeople = {
            id: null,
            id_people_type: null,
            id_people_race: null,
            sex: null,
            name: null,
            document: null,
            photo: null,
            date_of_birth: null,
          };
          this.loadDados();
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o Cadastro",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }


    }

  },
  components: {Layout, PageHeader, SchoolInput},
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="isLoadShow">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>


              <b-form @submit="save" class="was-validated">
                <b-form-group label="Nome da escola" label-for="input-1" description="Nome da escola completo">
                  <b-form-input id="input-1" v-model="form.name" required></b-form-input>
                </b-form-group>
                <b-form-group label="Identificador unico da escola" label-for="input-2" description="Identificador da escola">
                  <b-form-input id="input-2" v-model="form.iduuid" required></b-form-input>
                </b-form-group>
                <b-button type="submit" variant="primary">
                  <span v-if="form.id">Salvar alterações</span>
                  <span v-if="!form.id">Cadastrar novo</span>
                </b-button>
              </b-form>

              <div class="row" v-if="form.id">
                <template v-for="item in fields">
                  <div v-if="item.breakLine" class="col-12" :key="item.key">
                    <br><br>
                    <h3>{{ item.titleSession }}</h3><br>
                  </div>
                  <div class="col-12 col-md-4 col-lg-3 col-xl-3" :key="item.key">
                    <SchoolInput :config="item" @changeUpdate="updateConfig"/>
                  </div>


                </template>
              </div>


              <div v-if="form.id">
                <br>
                <br>
                <h3>Sócios/Administradores</h3>
                <br>


                <h4>Novo</h4>
                <b-form @submit="savePeople($event,formPeople)" class="was-validated row">

                  <!--                              id_people_type-->
                  <b-form-group class="col-sm-1" label="Tipo de pessoa" description="Selecione o tipo de pessoa">
                    <b-form-select
                        id="id_people_type"
                        v-model="formPeople.id_people_type"
                        :options="optionsPeopleTypes"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <!--                              id_people_race-->
                  <b-form-group class="col-sm-1" label="Raça pessoa" description="Selecione raça">
                    <b-form-select
                        id="id_people_race"
                        v-model="formPeople.id_people_race"
                        :options="optionsPeopleRaces"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <!--                              sex-->
                  <b-form-group class="col-sm-2" label="Sexo" description="Selecione o sexo">
                    <b-form-select
                        v-model="formPeople.sex"
                        :options="optionsSex"
                        required
                    ></b-form-select>
                  </b-form-group>
                  <!--                              name-->
                  <b-form-group class="col-sm-2" label="Nome" label-for="name" description="Nome da pessoa">
                    <b-form-input id="name" v-model="formPeople.name" required></b-form-input>
                  </b-form-group>
                  <!--                              document-->
                  <b-form-group class="col-sm-2" label="Documento" label-for="document" description="Documento da pessoa">
                    <b-form-input id="document" v-model="formPeople.document" required></b-form-input>
                  </b-form-group>
                  <!--                              photo-->
                  <b-form-group class="col-sm-1" label="Foto" label-for="photo" description="Foto da pessoa">
                    <b-form-file @change="handlerUpload" id="photo" v-model="formPeople.photo"></b-form-file>
                  </b-form-group>
                  <!--                              date_of_birth-->
                  <b-form-group class="col-sm-2" label="Data de nascimento" label-for="date_of_birth" description="Data de nascimento da pessoa">
                    <b-form-input type="date" id="date_of_birth" v-model="formPeople.date_of_birth" required></b-form-input>
                  </b-form-group>


                  <div class="col-sm-1">
                    <b-button type="submit" variant="primary" class="mt-4 btn-block">
                      <span>Salvar</span>
                    </b-button>
                  </div>

                </b-form>

                <br>
                <h4>Atuais</h4>

                <div v-for="people in form.schools_peoples" :key="people.id">


                  <b-form @submit="savePeople($event,people.peoples)" class="was-validated row">

                    <!--                              id_people_type-->
                    <b-form-group class="col-sm-1" label="Tipo de pessoa" description="Selecione o tipo de pessoa">
                      <b-form-select
                          id="id_people_type"
                          v-model="people.peoples.id_people_type"
                          :options="optionsPeopleTypes"
                          required
                      ></b-form-select>
                    </b-form-group>
                    <!--                              id_people_race-->
                    <b-form-group class="col-sm-1" label="Raça pessoa" description="Selecione raça">
                      <b-form-select
                          id="id_people_race"
                          v-model="people.peoples.id_people_race"
                          :options="optionsPeopleRaces"
                          required
                      ></b-form-select>
                    </b-form-group>
                    <!--                              sex-->
                    <b-form-group class="col-sm-2" label="Sexo" description="Selecione o sexo">
                      <b-form-select
                          v-model="people.peoples.sex"
                          :options="optionsSex"
                          required
                      ></b-form-select>
                    </b-form-group>
                    <!--                              name-->
                    <b-form-group class="col-sm-2" label="Nome" label-for="name" description="Nome da pessoa">
                      <b-form-input id="name" v-model="people.peoples.name" required></b-form-input>
                    </b-form-group>
                    <!--                              document-->
                    <b-form-group class="col-sm-2" label="Documento" label-for="document" description="Documento da pessoa">
                      <b-form-input id="document" v-model="people.peoples.document" required></b-form-input>
                    </b-form-group>
                    <!--                              photo-->
                    <b-form-group class="col-sm-1" label="Foto" label-for="photo" description="Foto da pessoa">
                      <b-form-file @change="handlerUpload" id="photo" v-model="people.peoples.photo"></b-form-file>
                      <img :src="people.peoples.photo_show" alt="">
                    </b-form-group>
                    <!--                              date_of_birth-->
                    <b-form-group class="col-sm-2" label="Data de nascimento" label-for="date_of_birth" description="Data de nascimento da pessoa">
                      <b-form-input type="date" id="date_of_birth" v-model="people.peoples.date_of_birth" required></b-form-input>
                    </b-form-group>


                    <div class="col-sm-1">
                      <b-button type="submit" variant="primary" class="mt-4 btn-block">
                        <span>Salvar</span>
                      </b-button>
                    </div>

                  </b-form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </b-overlay>

  </Layout>
</template>

<style scoped lang="scss">

</style>
