import loadMenu from "@/modules/load-menu";
export const menuItemsDefault = [
    // {
    //     id: 1,
    //     label: 'menuitems.dashboard.text',
    //     icon: 'ri-dashboard-line',
    //     link: '/'
    // },
    {
        id: 1.1,
        label: 'Meus contratos',
        icon: 'ri-dashboard-line',
        acl: 'is_user',
        link: '/cliente/contratos'
    },
    {
        id: 1.1,
        label: 'Meus pedidos',
        icon: 'ri-dashboard-line',
        acl: 'is_user',
        link: '/cliente/pedidos'
    },
    {
        id: 1.1,
        label: 'Minhas escolas',
        icon: 'ri-dashboard-line',
        acl: 'is_user',
        link: '/cliente/escolas'
    },

].concat(loadMenu);
