<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import SchoolsStore from "@/modules/schools/schools-store";
import Swal from "sweetalert2";
import SchoolsContractsStore from "@/modules/schools-contracts/schools-contracts-store";

export default {
  data: () => {
    return {
      title: "Meus contratos",
      items: [
        {
          text: "Genesis",
          href: "/",
        },
        {
          text: "Contratos",
          active: true,
        },
      ],

      loadingPage: false,
      list: [],
      contratos: [],

    }
  },
  components: {Layout, PageHeader},
  mounted() {
    const perfilJson = localStorage.getItem("perfil");
    this.perfil = JSON.parse(atob(perfilJson));
    this.getAll();

  },
  methods: {
    async getAll() {
      this.loadingPage = true;

      await SchoolsContractsStore.all({
        id_people: this.perfil.id_people
      }).then((response) => {
        const {data} = response.data;
        this.contratos = data;
      }).catch((error) => {
        console.log(error);
      })


      SchoolsStore.paginate({
        id_people: this.perfil.id_people,
        limit: 10000
      }).then((response) => {
        const {data} = response.data;
        this.list = data;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    novoContrato(escola) {
      console.log(escola);

      const form = {
        id_schools: escola.id,
        status: 'resume',
        id_peoples: this.perfil.id_people,
        description: 'Solicitação de novo contrato'
      }
      this.loadingPage = true;
      Swal.fire({
        title: 'Deseja solicitar um novo contrato para a escola ' + escola.name + '?',
        showDenyButton: true,
        confirmButtonText: `Sim`,
        denyButtonText: `Não`,
      }).then((result) => {
        if (result.isConfirmed) {
          SchoolsContractsStore.create(form).then((response) => {
            Swal.fire('Solicitação enviada com sucesso!', '', 'success');
            this.loadingPage = false;
            //close modal
            this.$bvModal.hide('modal-1');
          }).catch((error) => {
            console.log(error);
            Swal.fire('Erro ao enviar solicitação!', '', 'error');
            this.loadingPage = false;
          });
        } else if (result.isDenied) {
          Swal.fire('Solicitação cancelada!', '', 'info');
          this.loadingPage = false;
        }
      })

    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1 class="float-left">{{ title }}</h1>


              <b-button class="btn btn-info float-right" v-b-modal.modal-1>Solicitação de novo contrato</b-button>

              <b-modal id="modal-1" title="Pedido de novo contrato enviado!">
                <b-overlay :show="loadingPage">
                  <div v-for="escola in list" :key="escola.id">

                    <button type="button" @click="novoContrato(escola)" class="btn btn-success btn-block"><i>Solicitar novo contrato para a escola </i> <strong>{{ escola.name }}</strong></button>
                    <hr>

                  </div>
                </b-overlay>

              </b-modal>
            </div>
          </div>

          <br>
          <br>

          <div v-for="item in contratos" :key="item.id">

            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-4">
                    <span class="btn btn-success" v-if="item.status=='publish'">Aceito</span>
                    <span class="btn btn-warning" v-if="item.status=='resume'">rascunho</span>
                    <span class="btn btn-dark" v-if="item.status=='pending'">Pendente</span>

                    <h2>#{{ item.id }} - {{ item.schools.name}}</h2>
                    <h5>Por: {{ item.peoples.name }}</h5>
                    <h6 class="color-red">Data de vigencia: {{ item.date_start | f_date }} - {{ item.date_stop | f_date }}</h6>
                    <router-link :to="'/cliente/contrato/'+item.id" class="btn btn-outline-warning">Ver contrato</router-link>
                    <button v-if="item.status=='pending'" class="btn btn-danger ml-4">Assinar</button>
                  </div>
                  <div class="col" v-if="item.status!='resume'">
                    <h3>Assinaturas</h3>
                    <div class="row">
                      <div v-for="pessoa in item.assinaturas" :key="pessoa.id" class="col">
                        <h5>{{ pessoa.nome }}</h5>
                        <span class="badge badge-warning" v-if="pessoa.status=='pendente'">pendente</span>
                        <span class="badge badge-info" v-if="pessoa.status=='assinado'">assinado</span>
                        <span class="badge badge-light ml-2" v-if="pessoa.validate=='valido'">assinatura aprovada</span>
                        <span class="badge badge-danger ml-2" v-if="pessoa.validate=='pendente'">assinatura pendente</span>
                        <p>CPF: {{ pessoa.cpf }}<br>
                          RG: {{ pessoa.rg }}<br>
                          Data assinatura: {{ pessoa.data_assinatura }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped lang="scss">

</style>
